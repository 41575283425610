import React from "react";
import { withPrefix } from "gatsby";
import appTwoStyles from "./appTwo.module.scss";

const AppTwo = () => {
  return (
    <article className={appTwoStyles.content}>
      <div className={appTwoStyles.container}>
        <video
          className={appTwoStyles.appImageTwo}
          src={withPrefix("app_clip.mp4")}
          autoPlay
          muted
          loop
        >
          <source src={withPrefix("app_clip.mp4")} type="video/mp4" />
        </video>
        <div>
          <h2>Order a free lemonade on campus.</h2>
          <p>
            To test our system, we plan to deliver free lemonades on campus to
            UT-affiliated persons. Customers will be able order a lemonade in
            the App, and have it delivered to a select number of buildings.
            After this proof of concept, we hope to see our system integrate
            with UT dining services and other restaurants on campus.
          </p>
        </div>
      </div>
    </article>
  );
};

export default AppTwo;
