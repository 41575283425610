import React from "react";
import locStyles from "./loc.module.scss";
import { withPrefix } from "gatsby";

const Loc = () => {

  return (
    <article className={locStyles.content}>
      <div className={locStyles.container}>
        <video
          className={locStyles.locImage}
          src={withPrefix("loc.m4v")}
          autoPlay
          muted
          loop
        >
          <source src={withPrefix("loc.m4v")} type="video/mp4" />
        </video>
        <div>
          <h2>Our robots have a sense of where they are.</h2>
          <p>
            These robots will autonomously navigate around campus. To make this
            happen, a robot must be able to understand its current location and
            its surrounding environment. We accomplish this task by analyzing
            point cloud data collected from a lidar sensor and depth cameras.
            State of the art software identifies landmarks in this point cloud
            data and compares these identified features to a known map of the
            area to localize the robot.
          </p>
        </div>
      </div>
    </article>
  );
};

export default Loc;
