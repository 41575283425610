import React from "react";
import { withPrefix } from "gatsby";
import Layout from "../components/layout";
import Head from "../components/head";

import indexStyles from "./index.module.scss";
import Nav from "../components/features/nav";
import Loc from "../components/features/loc";
import Arch from "../components/features/arch";
import AppOne from "../components/features/appOne";
import AppTwo from "../components/features/appTwo";

const IndexPage = () => {
  return (
    <Layout>
      <Head title="Home" />
      {/* <div className={indexStyles.announcement}>
        <p>Try out the SMADS delivery system by ordering a lemonade!</p>
        <p>
          From 11/16 to 11/20 download the <a href={"https://apps.apple.com/us/app/texas-botler/id1536211396"}>Texas Botler</a> app in the App Store to
          place an order for a free lemonade.
        </p>
        <p>
          When signing up, make sure to use an @utexas.edu email!
        </p>
      </div> */}
      <div className={indexStyles.content}>
        <video
          className={indexStyles.video}
          src={withPrefix("jackal.mp4")}
          autoPlay
          muted
          loop
        >
          <source src={withPrefix("jackal.mp4")} type="video/mp4" />
        </video>
        <h2 className={indexStyles.description}>
          We are developing a system to make autonomous deliveries on the UT
          campus.
        </h2>
      </div>
      <div className={indexStyles.summary}>
        <p>
          An interdisciplinary team spanning across four UT labs is developing a
          building-to-building delivery system for the UT Austin campus using
          robots that will improve contactless deliveries and save people time.
          These robots will cross complex terrain, navigating around people,
          cars, and other obstacles typical to campus roadways. An iOS app will
          allow people to place, track and securely retrieve packages. From
          offices to student dormitories, these deliveries will improve safety
          and efficiency on the UT Austin campus and could be used to limit
          exposure during the COVID-19 pandemic.
        </p>
        <p>
          The initial project was funded by UT Good Systems and led by Dr.
          Junfeng Jiao, Dr. Luis Sentis, Dr. Joydeep Biswas, Dr. Justin Hart.
        </p>
      </div>
      <hr id="about" />
      <h2 className={indexStyles.featuresHeader}>
        Making autonomous deliveries requires coordinating many components.
      </h2>
      <Loc />
      <hr />
      <Nav />
      <hr />
      <Arch />
      <hr />
      <AppOne />
      <hr />
      <AppTwo />
    </Layout>
  );
};

export default IndexPage;
