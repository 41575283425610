import React from "react";
import archStyles from "./arch.module.scss";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const Arch = () => {
  const data = useStaticQuery(graphql`
    query {
      arch: file(relativePath: { eq: "images/arch.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <article className={archStyles.content}>
      <div className={archStyles.container}>
        <Img
          className={archStyles.archImage}
          fluid={data.arch.childImageSharp.fluid}
          alt="arch image"
        />
        <div>
          <h2>It all comes together in the architecture.</h2>
          <p>
            There are many independent software services working together to
            make autonomous robot deliveries possible. A sophisticated software
            architecture works to connect these components, ensuring that
            customer orders created in the App reach the robot for delivery and
            status updates reach the customer at every step without human
            intervention.
          </p>
        </div>
      </div>
    </article>
  );
};

export default Arch;
