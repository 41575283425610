import React from "react";
import appOneStyles from "./appOne.module.scss";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const AppOne = () => {
  const data = useStaticQuery(graphql`
    query {
      app: file(relativePath: { eq: "images/app.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <article className={appOneStyles.content}>
      <div className={appOneStyles.container}>
        <div>
          <h2>Our App keeps users updated at every step.</h2>
          <p>
            The App will allow customers to see the current location of their
            package and watch the movements of the delivery robot on a map. Once
            the robot arrives,the App will notify the customer that it is time
            to retrieve their package and send the customer a code to access
            their order.
          </p>
        </div>
        <Img
          className={appOneStyles.appImageOne}
          fluid={data.app.childImageSharp.fluid}
          alt="app image"
        />
      </div>
    </article>
  );
};

export default AppOne;
