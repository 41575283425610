import React from "react";
import navStyles from "./nav.module.scss";
import { withPrefix } from "gatsby";

const Nav = () => {

  return (
    <article className={navStyles.content}>
      <div className={navStyles.container}>
        <div>
          <h2>Our robots plan for unexpected obstacles.</h2>
          <p>
            Once a robot knows its location, it needs to plan a path to its
            destination and continuously adjust its movements to match that
            plan. At the same time, the robot must handle unexpected obstacles
            like cars, people and bikes in its path. A robust graph navigation
            planner undertakes these tasks, allowing robots to operate in a
            dynamic human environment.
          </p>
        </div>
        <video
          className={navStyles.navImage}
          src={withPrefix("nav.mp4")}
          autoPlay
          muted
          loop
        >
          <source src={withPrefix("nav.mp4")} type="video/mp4" />
        </video>
      </div>
    </article>
  );
};

export default Nav;
